import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { getStatusLabelClass, getTextLabelClass } from '../../../../core/modals/status.model';

@Component({
  selector: 'app-accordion-item',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './accordion-item.component.html',
  styleUrl: './accordion-item.component.scss',
})
export class AccordionItemComponent {
  @Input() open: boolean = true;
  @Input() title: string;
  @Input() count: number;
  @Input() color: string;
  @Input() itemId: string;

  getClass(color: string) {
    return getTextLabelClass(color);
  }
}
