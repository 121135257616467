<div class="accordion theme-accordion mt-3">
  <div class="accordion-item">
    <h2 class="accordion-header">
      <button
        class="accordion-button"
        type="button"
        data-bs-toggle="collapse"
        [attr.data-bs-target]="'#' + itemId"
        aria-expanded="true"
        aria-controls="collapseOne">
       <span class="{{getClass(color)}}">{{ title | translate }} {{count?'('+count+')':''}}</span>
      </button>
    </h2>
    <div [id]="itemId" class="accordion-collapse collapse" [class.open]="open">
      <div class="accordion-body">
        <div class="row">
          <ng-content></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
